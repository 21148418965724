<template>
  <div class="wrapper">
    <h4>{{ titleName }}</h4>
    <el-form :model="form" ref="form" :rules="rules" label-width="150px">
      <el-row :gutter="24">
        <el-col :span="8">
          <el-form-item label="服务项目" prop="serviceItem">
            <el-input v-model="form.serviceItem" placeholder="请输入服务项目" auto-complete="off"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="服务对象" prop="serviceOldPersonId">
            <el-input v-model="form.serviceOldPersonId" placeholder="请选择服务对象" auto-complete="off" :disabled="true">
              <el-button slot="append" icon="el-icon-search" @click="selectServiceOldPerson()"></el-button>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="服务人员" prop="serviceStaff">
            <el-input v-model="form.serviceStaff" placeholder="请选择服务人员" auto-complete="off" :disabled="true">
              <el-button slot="append" icon="el-icon-search" @click="selectServiceStaff()"></el-button>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="8">
          <el-form-item label="订单状态" prop="status">
            <el-select v-model="form.status" placeholder="请选择订单状态">
              <el-option v-for="item in homeServRecdStatusLst" :key="item.dictPidVal" :label="item.dictDesc" :value="item.dictPidVal"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="服务开始时间" prop="serviceBeginTime">
            <el-date-picker
              v-model="form.serviceBeginTime"
              type="datetime"
              format="yyyy-MM-dd HH:mm:ss"
              value-format="yyyy-MM-dd HH:mm:ss"
              placeholder="请选择服务开始时间"
            ></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="服务结束时间" prop="serviceEndTime">
            <el-date-picker
              v-model="form.serviceEndTime"
              type="datetime"
              format="yyyy-MM-dd HH:mm:ss"
              value-format="yyyy-MM-dd HH:mm:ss"
              placeholder="请选择服务结束时间"
            ></el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="8">
          <el-form-item label="满意度" prop="satisfaction">
            <el-input v-model="form.satisfaction" placeholder="请输入满意度" auto-complete="off"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="联系方式" prop="tel">
            <el-input v-model="form.tel" placeholder="请输入联系方式" auto-complete="off"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="服务区域（市）" prop="city">
            <el-input v-model="form.city" placeholder="请输入服务区域（市）" auto-complete="off"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="8">
          <el-form-item label="服务区域（区）" prop="area">
            <el-input v-model="form.area" placeholder="请输入服务区域（区）" auto-complete="off"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="服务区域（街道）" prop="street">
            <el-input v-model="form.street" placeholder="请输入服务区域（街道" auto-complete="off"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="服务区域（地址）" prop="address">
            <el-input v-model="form.address" placeholder="请输入服务区域（地址）" auto-complete="off"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item style="text-align: center" label-width="0">
            <el-button type="primary" icon="fa fa-check" @click="submitEvent('form')">保存</el-button>
            <el-button icon="fa fa-undo" @click="$router.go(-1)">返回</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-dialog title="选择服务对象" :visible.sync="visible" width="50%" :close-on-click-modal="false">
      <div>
        <div>
          服务对象姓名：
          <el-input style="width:200px;margin-right:10px;" size="medium" v-model="searchData.personName" placeholder="请输入服务对象姓名"></el-input>
          <el-button size="medium" type="primary" @click="search">搜索</el-button>
        </div>
        <div style="margin-top:10px;">
          <el-table :data="tableData" ref="singleTable" highlight-current-row border size="medium" :height="height">
            <el-table-column label="" width="40">
              <template slot-scope="scope">
                <el-radio
                  :label="scope.$index"
                  v-model="radio"
                  @change.native="getCurrentRow(scope.row)"
                  style="color: #fff;padding-left: 10px; margin-right: -25px;"
                ></el-radio>
              </template>
            </el-table-column>
            <el-table-column prop="personName" width="120" label="服务对象姓名"></el-table-column>
            <el-table-column prop="sex" label="性别" width="60"></el-table-column>
            <el-table-column prop="idNum" label="身份证" width="140"></el-table-column>
            <el-table-column prop="personLeveName" label="人员级别" width="120"></el-table-column>
            <el-table-column prop="usualAddress" label="常住地址" width="156"></el-table-column>
          </el-table>
          <div style="text-align: right; margin-top: 10px;">
            <el-pagination
              background
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="tablePage.currentPage"
              :page-sizes="[50, 100, 500]"
              :page-size="tablePage.pageSize"
              layout="total,jumper,prev, pager, next,sizes"
              :total="tablePage.totalResult"
            ></el-pagination>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancel()">取 消</el-button>
        <el-button type="primary" @click="cancel()">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="选择服务人员" :visible.sync="visible1" width="50%" :close-on-click-modal="false">
      <div>
        <div>
          服务人员姓名：
          <el-input style="width:200px;margin-right:10px;" size="medium" v-model="searchData1.perName" placeholder="请输入服务人员姓名"></el-input>
          <el-button size="medium" type="primary" @click="search1">搜索</el-button>
        </div>
        <div style="margin-top:10px;">
          <el-table :data="tableData1" ref="singleTable" highlight-current-row border size="medium" :height="height">
            <el-table-column label="" width="40">
              <template slot-scope="scope">
                <el-radio
                  :label="scope.$index"
                  v-model="radio1"
                  @change.native="getCurrentRow1(scope.row)"
                  style="color: #fff;padding-left: 10px; margin-right: -25px;"
                ></el-radio>
              </template>
            </el-table-column>
            <el-table-column prop="name" width="120" label="服务人员姓名"></el-table-column>
            <el-table-column prop="sex" label="性别" width="60"></el-table-column>
            <el-table-column prop="age" label="年龄" width="140"></el-table-column>
            <el-table-column prop="contTel" label="联系方式" width="120"></el-table-column>
            <el-table-column prop="jobTitle" label="职称" width="156"></el-table-column>
          </el-table>
          <div style="text-align: right; margin-top: 10px;">
            <el-pagination
              background
              @size-change="handleSizeChange1"
              @current-change="handleCurrentChange1"
              :current-page="tablePage1.currentPage"
              :page-sizes="[50, 100, 500]"
              :page-size="tablePage1.pageSize"
              layout="total,jumper,prev, pager, next,sizes"
              :total="tablePage1.totalResult"
            ></el-pagination>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancel1()">取 消</el-button>
        <el-button type="primary" @click="cancel1()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'AddOrEdit',
  data() {
    return {
      radio: '',
      height: 240,
      tableData: [],
      tablePage: {
        totalResult: 0,
        currentPage: 1,
        pageSize: 10,
        align: 'left',
        pageSizes: [10, 20, 50, 100, 500, 1000],
        perfect: true,
      },
      visible: false,
      searchData: {
        personName: '',
      },
      radio1: '',
      tableData1: [],
      tablePage1: {
        totalResult: 0,
        currentPage: 1,
        pageSize: 10,
        align: 'left',
        pageSizes: [10, 20, 50, 100, 500, 1000],
        perfect: true,
      },
      visible1: false,
      searchData1: {
        perName: '',
      },
      homeServRecdStatusLst: [],
      titleName: '',
      form: {},
      rules: {
        serviceItem: [{ required: true, message: '请输入服务项目', trigger: 'blur' }],
        serviceOldPersonId: [{ required: true, message: '请选择服务对象', trigger: 'blur' }],
        serviceStaff: [{ required: true, message: '请选择服务人员', trigger: 'blur' }],
        status: [{ required: true, message: '请选择状态', trigger: 'blur' }],
        serviceBeginTime: [{ required: true, message: '请选择服务开始时间', trigger: 'blur' }],
        serviceEndTime: [{ required: true, message: '请选择服务结束时间', trigger: 'blur' }],
        satisfaction: [{ required: true, message: '请输入满意度', trigger: 'blur' }],
        tel: [
          { required: true, message: '请输入联系方式', trigger: 'blur' },
          { pattern: /^(1[3456789]\d{9})$/, message: '请检查负责人联系方式格式' },
        ],
        city: [{ required: true, message: '请输入服务区域（市）', trigger: 'blur' }],
        area: [{ required: true, message: '请输入服务区域（区）', trigger: 'blur' }],
        street: [{ required: true, message: '请输入服务区域（街道）', trigger: 'blur' }],
        address: [{ required: true, message: '请输入服务区域（地址）', trigger: 'blur' }],
      },
    }
  },
  computed: {
    routeType() {
      return this.$route.params.type
    },
  },
  created() {
    this.initView()
    this.getDicList()
  },
  methods: {
    ...mapActions(['addOrSaveServiceRecord', 'detailServiceRecord', 'getSysDictServe', 'getHomePersonListServe', 'getPractitionerList']),
    getCurrentRow(row) {
      this.form.serviceOldPersonId = row.id
    },
    getCurrentRow1(row) {
      this.form.serviceStaff = row.id
    },
    cancel() {
      this.visible = false
    },
    cancel1() {
      this.visible1 = false
    },
    search() {
      this.getHomeOldPersonInfo()
    },
    search1() {
      this.getServiceStaffInfo()
    },

    handleSizeChange: function(size) {
      this.tablePage.pageSize = size
      this.getHomeOldPersonInfo()
    },
    handleSizeChange1: function(size) {
      this.tablePage1.pageSize = size
      this.getServiceStaffInfo()
    },
    // 控制页面的切换
    handleCurrentChange: function(currentPage) {
      this.tablePage.currentPage = currentPage
      this.getHomeOldPersonInfo()
    },
    // 控制页面的切换
    handleCurrentChange1: function(currentPage) {
      this.tablePage1.currentPage = currentPage
      this.getServiceStaffInfo()
    },
    getHomeOldPersonInfo() {
      let params = {
        current: this.tablePage.currentPage,
        size: this.tablePage.pageSize,
        param: {
          personName: this.searchData.personName,
        },
      }
      let data = null
      let server = 'getHomePersonListServe'
      this[server](params).then((res) => {
        if (res.code == 200) {
          this.tableData = res.data.records
          this.tablePage.totalResult = +res.data.total
        }
      })
    },
    getServiceStaffInfo() {
      let params = {
        current: this.tablePage1.currentPage,
        size: this.tablePage1.pageSize,
        param: {
          perName: this.searchData1.perName,
        },
      }
      let data = null
      let server = 'getPractitionerList'
      this[server](params).then((res) => {
        if (res.code == 200) {
          this.tableData1 = res.data.records
          this.tablePage1.totalResult = +res.data.total
        }
      })
    },
    getDicList() {
      this.getSysDictList('homeServRecdStatus', 'homeServRecdStatusLst')
    },
    // 获取数据字典
    getSysDictList(code, codeList) {
      let params = {
        typeCode: code,
      }
      this.getSysDictServe(params).then((res) => {
        if (res.code == 200) {
          this[codeList] = res.data
        }
      })
    },
    initView() {
      if (this.routeType == 'add') {
        this.titleName = '添加服务记录'
      } else {
        this.titleName = '修改服务记录'
        this.itemData = JSON.parse(this.$route.query.item)
        //这里调用请求编辑时的详情接口渲染form表单数据
        let params = {
          id: this.itemData.id,
        }
        let data = null
        let server = 'detailServiceRecord'
        this[server](params).then((res) => {
          if (res.code == 200) {
            data = res.data
            this.form = {
              id: data.id,
              serviceItem: data.serviceItem,
              serviceOldPersonId: data.serviceOldPersonId,
              serviceStaff: data.serviceStaff,
              status: data.status,
              serviceBeginTime: data.serviceBeginTime,
              serviceEndTime: data.serviceEndTime,
              satisfaction: data.satisfaction,
              tel: data.tel,
              city: data.city,
              area: data.area,
              street: data.street,
              address: data.address,
            }
          }
        })
      }
      this.getHomeOldPersonInfo()
      this.getServiceStaffInfo()
    },
    submitEvent(formName) {
      if (!this.form.serviceOldPersonId) {
        this.$XModal.message({
          message: '请检查服务对象是否已选择',
          status: 'warning',
        })
        return
      }
      if (!this.form.serviceStaff) {
        this.$XModal.message({
          message: '请检查服务人员是否已选择',
          status: 'warning',
        })
        return
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let startTime = Date.parse(this.form.serviceBeginTime)
          let endTime = Date.parse(this.form.serviceEndTime)
          if (startTime > endTime) {
            this.$XModal.message({
              message: '服务结束时间应该大于服务开始时间',
              status: 'warning',
            })
            return
          }
          let source = null
          if (this.$route.query.source == 'serviceStation') {
            source = 11
          }
          if (this.$route.query.source == 'sumCenter') {
            source = 12
          }
          if (this.$route.query.source == 'generalServiceCenter') {
            source = 13
          }
          if (this.$route.query.source == 'mktCompany') {
            source = 15
          }
          let params = {
            ...this.form,
            serviceOrgId: this.$route.params.serviceOrgId,
            source,
            serviceTime: null,
          }
          let server = 'addOrSaveServiceRecord'
          this[server](params).then((res) => {
            if (res.code == 200) {
              this.$XModal.message({
                message: this.routeType == 'add' ? '添加成功' : '修改成功',
                status: 'success',
              })
              this.$router.go(-1)
            }
            this.loading = false
          })
        } else {
          return false
        }
      })
    },
    selectServiceOldPerson() {
      this.visible = true
    },
    selectServiceStaff() {
      this.visible1 = true
    },
  },
}
</script>

<style lang="scss" scoped>
.wrapper {
  padding: 10px;
  overflow: hidden;
  h4 {
    height: 40px;
    line-height: 40px;
    color: #333;
    padding-left: 20px;
    margin-bottom: 20px;
  }
}
</style>
